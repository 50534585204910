.board {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    height: 502px;
    font-size: 20px;
    border-radius: 5px;
    position: relative;
    margin: 50px auto;
    box-sizing: border-box;
    background-image: url("./../../../../../assets/images/Game Image/snkae and ladder/bg-image.png");
    background-size:100% ;
    background-repeat: no-repeat;
  }
  /* border: 1px solid black; */
   .cell {
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: #333;
    position: relative;
    z-index: 100;
  } 
   
  /* border: 1px solid black; */
  .cell-99,
  .cell-3,
  .cell-5,
  .cell-7,
  .cell-9,
  .cell-11,
  .cell-13,
  .cell-15,
  .cell-17,
  .cell-19,
  .cell-21,
  .cell-23,
  .cell-25,
  .cell-27,
  .cell-29,
  .cell-31,
  .cell-33,
  .cell-35,
  .cell-37,
  .cell-39,
  .cell-41,
  .cell-43,
  .cell-45,
  .cell-47,
  .cell-49,
  .cell-51,
  .cell-53,
  .cell-55,
  .cell-57,
  .cell-59,
  .cell-61,
  .cell-63,
  .cell-65,
  .cell-67,
  .cell-69,
  .cell-71,
  .cell-73,
  .cell-75,
  .cell-77,
  .cell-79,
  .cell-81,
  .cell-83,
  .cell-85,
  .cell-87,
  .cell-89,
  .cell-91,
  .cell-93,
  .cell-95,
  .cell-97,
  .cell-1 {
    /* background-color: #fedbd0; */
  }
  
  .cell-2,
  .cell-4,
  .cell-6,
  .cell-8,
  .cell-10,
  .cell-12,
  .cell-14,
  .cell-16,
  .cell-18,
  .cell-20,
  .cell-22,
  .cell-24,
  .cell-26,
  .cell-28,
  .cell-30,
  .cell-32,
  .cell-34,
  .cell-36,
  .cell-38,
  .cell-40,
  .cell-42,
  .cell-44,
  .cell-46,
  .cell-48,
  .cell-50,
  .cell-52,
  .cell-54,
  .cell-56,
  .cell-58,
  .cell-60,
  .cell-62,
  .cell-64,
  .cell-66,
  .cell-68,
  .cell-70,
  .cell-72,
  .cell-74,
  .cell-76,
  .cell-78,
  .cell-80,
  .cell-82,
  .cell-84,
  .cell-86,
  .cell-88,
  .cell-90,
  .cell-92,
  .cell-94,
  .cell-96,
  .cell-98,
  .cell-100 {
    /* background-color: #fff8dc; */
  }