.results {
    position: absolute;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  
  .results .wrapper2 {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  }
  
  .results .wrapper2 .text {
    display: flex;
    flex-direction: column;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 4rem;
    text-shadow: 10px 10px 2px rgba(0, 0, 0, 0.25);
  }
  
  .results .active  {
    opacity: 1;
    transform: scale(1);
    z-index: 4567890;
    position: relative;
    /* background-color: rgba(0,0,0,0.5); */
    bottom: 50px;
  }
  