.scoreboard {
    position: absolute;
    top: -100%;
    width: 100%;
    transition: top 0.25s ease-in-out;
  }
  
  .scoreboard .players {
    display: flex;
    justify-content: center;
  }
  
  .scoreboard .players .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 2em;
  }
  
  .scoreboard .players .player .name {
    font-weight: 200;
    font-size: 1.5rem;
  }
  
  .scoreboard .players .player .score1 {
    margin: 0.5rem;
    font-weight: 900;
    font-size: 2rem;
    text-shadow: 10px 2px 2px rgba(0, 0, 0, 0.25);
  }
  
  .scoreboard.active {
    top: 0;
  }
  