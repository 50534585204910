

.hand1 .active {
    left: 2em;
}

.hand1 .playerOne {
    position: absolute;
    left: -160px;
    filter: drop-shadow(20px 20px 2px rgba(0, 0, 0, 0.25));
    transform-origin: left;
    --scale: 1;
    transition: left 0.5s ease-in-out;
}

.hand1 .playerTwo {
    position: absolute;
    right: -40vw;
    filter: drop-shadow(-20px 20px 2px rgba(0, 0, 0, 0.25));
    transform-origin: left;
    --scale: -1;
    transition: right 0.5s ease-in-out;
    transform: scaleX(var(--scale));
}

.hand1 .moving {
    animation: moving 1s ease-in-out infinite;
}

.hand1 .image {
    width: clamp(300px, 30vw, 2500px);
    top: 30%;
}

.hand1 .activeLeft {
    left: -100%;
}

.hand1 .activeRight {
    right: -100%;
}

@keyframes moving {
    0% {
        -webkit-transform: scaleX(var(--scale)) rotate(0deg);
        transform: scaleX(var(--scale)) rotate(0deg);
    }
    50% {
        -webkit-transform: scaleX(var(--scale)) rotate(0deg);
        transform: scaleX(var(--scale)) rotate(-30deg);
    }
    100% {
        -webkit-transform: scaleX(var(--scale)) rotate(0deg);
        transform: scaleX(var(--scale)) rotate(0deg);
    }
}
