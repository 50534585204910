@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Barlow", sans-serif;
}
.rpsh1 {
  width: 95vw;
  height: 100vh;
  background: radial-gradient(
    134.34% 134.34% at 50% 0%,
    #1f3757 0%,
    #131537 100%);
  
}
.wrapper1 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
#wrapper1{
  display: none;

}
.rocpepsic1{
  margin-top: 0rem;
  margin-right: 10rem;
}
  #rocpepsic1{ 
    display: block;

  }

.twobtnw{
  padding-top: 5px;
  display: flex;
  justify-content: center;
}
#twobtnw{
  display: flex;
  /* visibility: visible; */
  justify-content: center;
}
/* CSS */
.player {
  text-align: center;
}
/* CSS */
.pvc {

  border-radius: 30px;
  padding: 5px;
  border: 7px solid #de415d;
  font-weight: 600;
  line-height: 30px;
  font-size: 24px;
  /* display: flex; */
  margin-top: 15px;
  margin-bottom: 15px;
  width: 18rem;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 4rem;

}
.pvc:hover{
  box-shadow: rgb(255, 198, 0) ;

}


@media (min-width: 768px) {
  .pvc {
    font-size: 24px;
    min-width: 196px;
  }
}



.pvc1{
  border-radius: 30px;
    padding: 5px;
    border: 7px solid #eca61f;
    font-weight: 600;
    line-height: 30px;
    font-size: 24px;
    padding-top: 10px;
    width: 18rem;
    height: 4rem;
    /* display: flex; */
    margin-top: 15px;
    margin-left: 10px;
}



/* .hands{
    width: 467px;
    height: 430px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 100px;
    background-image: url('./../../../images/Game Image/rps game/triangle.png');
    background-repeat: no-repeat;
    background-position: center;
}
.scissors2{
    margin-left: 20px;
}
.paper2{
    margin-right: 20px;
}
.hands2 .hand2{
    cursor: pointer;
    transition: all 0.2s;
}
.hands2 .hand2:hover{
    transform: translateY(-8px);
} */
