/* .pvp32 {
    margin: 0;
    padding: 0;
    background: #392c87;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    color: white;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} */
.row {
    --bs-gutter-x: 0rem !important;
  }
* {
    font-family: "Montserrat", sans-serif;
}
.chhand{
    height: 92vh;
  background-color: #131a28;
  color: #1cdbf5;
  position: relative;
}
.proom{
    background-color: #1cdbf5;
    z-index: 1;
    position: relative;
}
ul {
    padding: 0;
    margin: 0;
}
.rpsmside{
    position: relative;
    z-index: 1;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,700&display=swap');
