.for-box{
    background-color: var(--primary);
      border-radius: 1rem;
      padding: 3rem 2rem;
      
  }
  .for-box h4{
      color: var(--greenYellow);
      font-size: 2.5rem;
      font-weight: 500;
      padding-bottom: 1.5rem;
  }