.row {
    --bs-gutter-x: 0rem !important;
  }

.rock-paper-scissors-side{
    position: relative;
    z-index: 1;
}


.rock-paper-scissors-computer-hand{
    height: 92vh;
    background-color: #131a28;
    color: #1cdbf5;
    position: relative;
}

.rock-paper-scissors-main-details{
    background-color: #1cdbf5;
    z-index: 1;
    position: relative;
}



.hands2 {
    position: absolute;
    top: 0;
    left: -20%;
    width: 140%;
    height: 60%;
    display: flex;
    align-items: center;
    z-index: 1;
    }
    
    .names {
    position: absolute;
    bottom: 0px;
    }
    
    

     .picker {
        position: absolute;
        bottom: -100%;
        width: 100%;
        transition: bottom 0.5s ease-in-out;
        max-width: 1500px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .picker .options {
        display: flex;
        justify-content: space-around;
    }
    
    .picker .options .image {
        overflow: hidden;
        width: clamp(300px, 50vw, 2500px);
        transform: translateY(0) rotate(-90deg);
        transition: all 0.3s;
        filter: drop-shadow(-20px 20px 2px rgba(0, 0, 0, 0.25));
    }
    
    .picker .options .image:hover {
        transform: translateY(-30%) rotate(-90deg);
        filter: drop-shadow(-60px 20px 2px rgba(0, 0, 0, 0.25));
        cursor: pointer;
    }
    
    .picker.active {
        bottom: 0;
        
    }
    .play-again{
        display: flex;
        width: 10rem;
        font-size: 2rem;
        justify-content: center;
    }
