.roomcode {
    position: absolute;
    top: 1em;
    left: -100%;
    display: flex;
    gap: 0.75em;
    color: #fff;
    background: #1cdbf5;
    padding: 0.4em 1em;
    border-radius: 0.5em;
    filter: drop-shadow(10px 10px 2px rgba(0, 0, 0, 0.25));
    transition: left 0.25s ease-in-out;
    z-index: 1;
    cursor: pointer;
}

.roomcode:active {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    transform: translate(5px, 5px);
}

.roomcode .code {
    font-weight: 700;
    font-size: 1.2rem;
    /* text-shadow: 5px 5px 2px rgba(0, 0, 0, 0.25); */
}

.roomcode .icon {
    width: 1.5em;
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.25));
}

.roomcode.active {
    left: 1em;
}
