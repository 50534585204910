@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Barlow", sans-serif;
}
.rps {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    134.34% 134.34% at 50% 0%,
    #1f3757 0%,
    #131537 100%);
  
}
.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}