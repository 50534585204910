/* Player.css */
 .snake-and-ladder-player {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: blue;
    bottom: 0;
    left: 0;
    transition: bottom 4s, left 4s;
}    