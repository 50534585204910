.snakemenu {
    /* position: absolute; */
    /* width: 100vw; */
    height: 100vh;
    z-index: 1;
    overflow-y: auto;
  }
  .snakemenu .snaketitle {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 0.9;
    text-shadow: 8px 8px 2px rgba(0, 0, 0, 0.25);
    margin: 1em 0 10vh 0;
  }
  .snakemenu .snaketitle .sanketi{ font-weight: 800;
    font-size: 75px;}
  .snakemenu .snaketitle .andti {  font-weight: 100;
    font-size: 90px;}
  .snakemenu .snaketitle .ladderti{  font-style: italic;
    font-weight: 550;
    font-size: 60px;}
  .snakemenu .snake-Input-name {
    width: 20em;
    margin: auto;
  }
  .snakemenu .snake-Input-name  > *:not(:last-child):not(label)  {
    margin-bottom: 20px;
  
}
  .snakemenu .snake-Input-name .input{
    width: 100%;
    height: 3em;
    font-weight: 700;
    font-size: 1.1em;
    color: #131a28;
    background: none;
    outline: 0;
    border: 0;
    border-bottom: 2px solid white;
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.25));
  }

  .snakemenu .snake-Input-name .input::placeholder{
    font-weight: 400;
  color: #131a28;
}

.snakemenu .snake-start-game{
    width: 100%;
  height: 2.8em;
  border-radius: 0.4em;
  outline: 0;
  border: 0;
  font-weight: 900;
  font-size: 1.2em;
  color: #131a28;
  filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  text-transform: uppercase;
}
.snakemenu .snake-start-game :active {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    transform: translate(5px, 5px);
  }